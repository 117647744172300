// Body
$body-bg: #fff;

$sidebar-width : 0px; // Mettre à 0 s'il n'y a pas de sideMenu ou 250px s'il y en a un
$sidebar-left : 0px; // Mettre à 0 s'il n'y a pas de sideMenu ou 40px s'il y en a un

$header-height : 80px; // Mettre à 0 s'il n'y a pas de topMenu ou 80px s'il y en a un (ajuster)

$logo-height: 70px; // Mettre à 60 px s'il est dans un topMenu ou ajuster s'il est en sideMenu
$logo-mobile-height : 55px;
$sidebar-admin : 280px;

// Typography
$secondary-font: "Robota", sans-serif;
$primary-font: "Roboto", sans-serif;
$fa-font-path: "../fonts" !default;
$text-color: #1e4d94;
$color-primary: #284e7b;
$color-primary-light: #3567A2;
$color-primary-dark: #1F3D5F;
$color-secondary-light: #3567A2;
$color-secondary: #284e7b;
$color-secondary-dark: #1F3D5F;
$color-alternate: #F9F9F9;
$headings-font-weight: 600 !default;
// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: #555555;
$btn-primary-background: $color-primary;

// Modal
$modal-xl: 1140px !default;

//Image
$img-path: "/images/" !default;
$lg-path-fonts: '/fonts'!default;
$lg-path-images: '/images/'!default;

//** Global textual link color.
$link-color:            black !default;

//== Components
$border-radius-base:        0 !default;
$border-radius-large:       0 !default;
$border-radius-small:       0 !default;

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;


// Liens
