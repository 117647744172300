// Fonts
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,600");
@import "~bootstrap/scss/bootstrap";
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
// Variables
@import "../front/variables";
@import "resources/assets/libs/jquery-sweetalert/sweetalert.scss";
@import "resources/assets/libs/bootstrap-switch/bootstrap-switch.min.scss";
@import "~bootstrap-select/sass/bootstrap-select";
@import "~datatables.net-bs4/css/dataTables.bootstrap4.min.css";
@import "node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker";
@import "~dropzone/dist/dropzone";


